import { processingForm } from "@/helpers/processing-form";
import router from "@/router";
import { getAllUrlParameters } from "@/helpers/url-parameter";

const state = () => ({
  status: null,
  description: null,
  amount: null,
  uuid: null,
  isStatusFinal: null,
  error: null,
  shop: null,
  setIframeMode: false,
});
let loading = false;
const actions = {
  async checkStatus({ commit, dispatch }, uuid) {
    if (loading) return;
    try {
      loading = true;
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const { data } = await this.$axios(`api/v1/transactions/status/${uuid}`, {
        disableErrorNotification: true,
      });
      if (data.status === "processing" && data.authPayload?.mode) {
        const { mode } = data.authPayload;
        switch (mode) {
          case "form": {
            processingForm(data.authPayload);
            break;
          }
          case "otp": {
            const params = getAllUrlParameters();
            router.replace({ path: `/widget/${uuid}`, query: { ...params } });
            break;
          }
          case "render_html_on_new_page": {
            const authPayload = data.authPayload;
            localStorage.setItem("redirect-page-content", authPayload.content);
            router.replace({ path: `/redirect/${authPayload.id}` });
            break;
          }
          case "renderHtml": {
            const authPayload = data.authPayload;
            dispatch("renderHtmlForm", authPayload.content);

            const params = getAllUrlParameters();
            router.replace({
              path: `/result/${authPayload.id}`,
              query: { ...params },
            });
            break;
          }
          case "redirect": {
            if (data.authPayload?.content?.action) {
              window.location.href = data.authPayload.content.action;
            }
            break;
          }
          case "iframe": {
            const element = document.getElementById("resultIframeId");

            if (window.self !== window.top) {
              element.style.position = "fixed";
              element.style.height = "100%";
              element.style.width = "100%";
              element.style.top = "0";
              element.style.left = "0";

              commit("setIframeMode", true);
            } else {
              element.style.position = "fixed";
              element.style.maxWidth = "780px";
              element.style.height = "100%";
              element.style.maxHeight = "560px";

              if (document.body.clientWidth < 600) {
                element.style.height = "100%";
                element.style.width = "100%";
                element.style.top = "0";
                element.style.left = "0";
              } else {
                element.style.top = "0";
                element.style.bottom = "0";
                element.style.left = "0";
                element.style.right = "0";
                element.style.marginTop = "auto";
                element.style.marginBottom = "auto";
                element.style.marginLeft = "auto";
                element.style.marginRight = "auto";
              }
            }

            element.innerHTML = data.authPayload.content["iframe"];

            element.querySelector("form").submit();
            break;
          }
          case "hiddenIframe": {
            const container = document.createElement("div");
            const authPayload = data.authPayload;
            container.innerHTML = authPayload.content["iframe"];
            document.body.append(container);
            await container.querySelector("form").submit();

            if (authPayload.content["acsRedirect"]) {
              window.open(authPayload.content["acsRedirect"], "_self");
            } else if (authPayload.content["id"]) {
              const params = getAllUrlParameters();
              router.replace({
                path: `/result/${authPayload.content["id"]}`,
                query: { ...params },
              });
            }

            break;
          }
        }
      }

      commit("setShop", data.shop);
      commit("setTransactionData", data);
      loading = false;
    } catch (error) {
      loading = false;
      if (404 === error?.response?.status) {
        commit("setTransactionData", {
          isStatusFinal: true,
          status: "failed",
          error: "Transaction not found",
        });
      }
    }
  },
};

const mutations = {
  setTransactionData(state, payload) {
    state.status = payload.status;
    state.description = payload.description;
    state.amount = payload.amount;
    state.uuid = payload.uuid;
    state.isStatusFinal = payload.isStatusFinal;
    state.error = payload.error;
  },
  setShop(state, payload) {
    state.shop = payload;
  },
  setIframeMode(state, payload) {
    state.isIframeMode = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
