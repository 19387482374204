export const processingForm = (payload) => {
  const form = document.createElement("form")
  form.method = "POST"
  form.action = payload.content.action

  const { fields } = payload.content

  Object.entries(fields).forEach(([key, value]) => {
    const input = document.createElement("input")
    input.name = key
    input.value = value
    input.type = "hidden"

    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
}
