import { getBrand } from "@/helpers/brand"

export const getFavicon = () => {
  const brand = getBrand()

  switch (brand) {
    case "Slots Pay":
      return "favicon-slots-pay.ico"
    case "A1 Pay":
      return "favicon-a1-pay.ico"
    default:
      return "favicon-default.ico"
  }
}
