import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/widget/:uuid",
    name: "Widget",
    component: () =>
      import(/* webpackChunkName: "widget-page" */ "@/views/Widget.vue"),
  },
  {
    path: "/result/:uuid",
    name: "Result",
    component: () =>
      import(/* webpackChunkName: "result-page" */ "@/views/Result.vue"),
  },
  {
    path: "/otp-check/:uuid",
    name: "OtpCheck",
    component: () =>
      import(/* webpackChunkName: "redirect-page" */ "@/views/OtpCheck.vue"),
  },
  {
    path: "/redirect/:uuid",
    name: "Redirect",
    component: () =>
      import(/* webpackChunkName: "redirect-page" */ "@/views/Redirect.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
