import { getUrlParameter } from "./url-parameter";

export const THEME = {
  SLOTOKING: "Slotoking",
  TRIPPLE_SEVEN_WHITE: "777",
  TRIPPLE_SEVEN_BLACK: "777-Black",
  NLC: "Nolimitcoin",
  FORTUNE_WHEELZ: "FortuneWheelz",
  JACKPOTT_RABBIT: "JackpottRabbit",
};

export function appTheme() {
  const theme = getUrlParameter("theme");

  switch (theme) {
    case THEME.SLOTOKING:
      return slotoKingTheme();
    case THEME.TRIPPLE_SEVEN_WHITE:
      return thrippleSevenWhiteTheme();
    case THEME.TRIPPLE_SEVEN_BLACK:
      return trippleSevenBlackTheme();
    case THEME.JACKPOTT_RABBIT:
      return jackpottRabbitTheme();
    case THEME.NLC:
      return nlcTheme();
    case THEME.FORTUNE_WHEELZ:
      return fortuneWheelzTheme();
    default:
      return slotoKingTheme();
  }
}

export const appThemeFontFamily = () => {
  const theme = getUrlParameter("theme");

  switch (theme) {
    case THEME.SLOTOKING:
      return "Roboto, sans-serif";
    case THEME.TRIPPLE_SEVEN_WHITE:
      return "Geologica, sans-serif";
    case THEME.TRIPPLE_SEVEN_BLACK:
      return "Geologica, sans-serif";
    case THEME.NLC:
      return "ReadexPro, sans-serif";
    case THEME.FORTUNE_WHEELZ:
      return "Rubik, sans-serif";
    case THEME.JACKPOTT_RABBIT:
      return "WorkSans, sans-serif";
    default:
      return "Roboto, sans-serif";
  }
};
export const appThemeName = (shop) =>
  getUrlParameter("theme") || shop?.theme || "";

function slotoKingTheme() {
  return {
    dark: false,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: "#4E4D5E",
      background: "#fff",
      cardSecondaryColor: "#4E4D5E",
      cardBg: "#FFF",
      inputColor: "#4E4D5E",
      inputLabel: "#4E4D5E",
      inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: "#B3B3B3",
      inputBorder: "#B3B3B3",
      inputBorderFocused: "#6b6b6b",
      inputBackground: "#FFF",
      submitColor: "#4E4D5E",
      submitBg: "#FFE600",
      submitHoverBg: "#e5ce00",
      submitActiveBg: "#bfac00",
      submitDisabledBg: "#fff499",
      submitDisabledColor: "#B3ADCC",
      // submitBorder: "#09080D",
      // submitBoxShadow: "#000",
      submitScdColor: "#4E4D5E",
      submitScdBg: "#FFE600",
      submitScdHoverBg: "#e5ce00",
      submitScdActiveBg: "#bfac00",
      // submitScdDisabledBg: "#",
      // submitScdBorder: "#",
      // submitScdBoxShadow: "",
      linkColor: "#6969cd",
      linkColorHover: "#5b5ba8",
      linkColorActive: "#8989d7",
      loaderColor: "#FFE600",
      error: "#E05252",
    },
  };
}

function thrippleSevenWhiteTheme() {
  return {
    dark: false,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: "#09080D",
      background: "#fff",
      cardSecondaryColor: "#09080D",
      cardBg: "#FFF",
      inputColor: "#4A426B",
      inputLabel: "#4A426B",
      inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: "#796EA6",
      inputBorder: "#D0CCE0",
      inputBorderFocused: "#6d619f",
      inputBackground: "#FFF",
      submitColor: "#09080D",
      submitBg: "#FFBD00",
      submitHoverBg: "#D19B00",
      submitActiveBg: "#9E7500",
      submitDisabledBg: "#FFE499",
      submitDisabledColor: "#B3ADCC",
      submitBorder: "#09080D",
      submitBoxShadow: "#000",
      submitScdColor: "#09080D",
      submitScdBg: "#FFBD00",
      submitScdHoverBg: "#D19B00",
      submitScdActiveBg: "#9E7500",
      submitScdDisabledBg: "#000",
      submitScdBorder: "#09080D",
      submitScdBoxShadow: "#000",
      linkColor: "#6969cd",
      linkColorHover: "#5b5ba8",
      linkColorActive: "#8989d7",
      loaderColor: "#FFBD00",
      error: "#E05252",
    },
  };
}

function trippleSevenBlackTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: "#FFF",
      background: "#030508",
      cardSecondaryColor: "#FFF",
      cardBg: "#0D1826",
      inputColor: "#FFF",
      inputLabel: "#796EA6",
      inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: "#B3ADCC",
      inputBorder: "#796EA6",
      inputBorderFocused: "#a199c0",
      inputBackground: "#030508",
      submitColor: "#09080D",
      submitBg: "#FFBD00",
      submitHoverBg: "#D19B00",
      submitActiveBg: "#9E7500",
      submitDisabledBg: "#FFE499",
      submitDisabledColor: "#B3ADCC",
      submitBorder: "#09080D",
      // submitBoxShadow: "",
      submitScdColor: "#09080D",
      submitScdBg: "#FFBD00",
      submitScdHoverBg: "#D19B00",
      submitScdActiveBg: "#9E7500",
      // submitScdDisabledBg: "#",
      submitScdBorder: "#09080D",
      // submitScdBoxShadow: "",
      linkColor: "#796EA6",
      linkColorHover: "#5b5ba8",
      linkColorActive: "#8989d7",
      loaderColor: "#F1C21C",
      error: "#E05252",
    },
  };
}

function jackpottRabbitTheme() {
  return {
    dark: true,
    colors: {
      // // primary: "#",
      // // secondary: "#",
      color: "#FFF",
      background: "#0B080C",
      cardSecondaryColor: "#FFF",
      cardBg: "#35293D",
      inputColor: "#FFF",
      inputLabel: "#FFF",
      inputLabelAsteriks: "#FF0101",
      inputPlaceholderColor: "#C0AFCA",
      inputBorder: "#9072A1",
      inputBorderFocused: "#ac95b8",
      inputBackground: "#1C141F",
      submitColor: "#0B080C",
      submitBg: "#EBFF07",
      submitHoverBg: "#d9ec00",
      submitActiveBg: "#c1d200",
      submitDisabledBg: "#626B00",
      submitDisabledColor: "#35293D",
      // // submitBorder: "",
      // // submitBoxShadow: "",
      submitScdColor: "#FFF",
      submitScdBg: "#35293D",
      submitScdHoverBg: "#1d1722",
      submitScdActiveBg: "#251d2b",
      submitScdDisabledBg: "#5f4a6e",
      // submitScdBorder: "#",
      // submitScdBoxShadow: "",
      linkColor: "#FFF",
      linkColorHover: "#e6e6e6",
      linkColorActive: "#CCC",
      loaderColor: "#EBFF07",
      error: "#FF0101",
    },
  };
}

function nlcTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: "#FFF",
      background: "#141722",
      cardSecondaryColor: "#FFF",
      cardBg: "#23293B",
      inputColor: "#FFF",
      inputLabel: "#FFF",
      inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: "#9AA0B1",
      inputBorder: "#9AA0B1",
      inputBorderFocused: "#cccfd8",
      inputBackground: "#141722",
      submitColor: "#FFF",
      submitBg: "#F31D53",
      submitHoverBg: "#e80c44",
      submitActiveBg: "#b50a35",
      submitDisabledBg: "#fff499",
      submitDisabledColor: "#FFF",
      // submitBorder: "",
      // submitBoxShadow: "",
      submitScdColor: "#FFF",
      submitScdBg: "#F31D53",
      submitScdHoverBg: "#e80c44",
      submitScdActiveBg: "#b50a35",
      // submitScdDisabledBg: "#",
      // submitScdBorder: "#",
      // submitScdBoxShadow: "",
      linkColor: "#6969cd",
      linkColorHover: "#5b5ba8",
      linkColorActive: "#8989d7",
      loaderColor: "#F1C21C",
      error: "#E05252",
    },
  };
}

function fortuneWheelzTheme() {
  return {
    dark: true,
    colors: {
      // primary: "#",
      // secondary: "#",
      color: "#F8FAFC",
      background: "#0E201D",
      cardSecondaryColor: "#F8FAFC",
      cardBg: "#538969",
      inputColor: "#02170F",
      inputLabel: "#F8FAFC",
      inputLabelAsteriks: "#E05252",
      inputPlaceholderColor: "#64868B",
      inputBorder: "#CBE1DD",
      inputBorderFocused: "#538969",
      inputBackground: "#F8FAFC",
      submitColor: "#02170F",
      submitBg: "#F9D01A",
      submitHoverBg: "#e3bb05",
      submitActiveBg: "#c8a405",
      submitDisabledBg: "#B39305",
      submitDisabledColor: "#594902",
      // submitBorder: "#09080D",
      // submitBoxShadow: "#000",
      submitScdColor: "#02170F",
      submitScdBg: "#F9D01A",
      submitScdHoverBg: "#e3bb05",
      submitScdActiveBg: "#c8a405",
      // submitScdDisabledBg: "#",
      // submitScdBorder: "#",
      // submitScdBoxShadow: "",
      linkColor: "#6969cd",
      linkColorHover: "#5b5ba8",
      linkColorActive: "#8989d7",
      loaderColor: "#F9D01A",
      error: "#E05252",
    },
  };
}
