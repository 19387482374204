const state = () => ({
  lastAction: {
    name: "",
    payload: null,
  },
});

const mutations = {
  setLastAction(state, payload) {
    state.lastAction = payload;
  },
};

export default {
  state,
  mutations,
};
