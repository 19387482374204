import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import uk from "vuetify/lib/locale/uk"
import en from "vuetify/lib/locale/en"

import { appLanguage } from "@/helpers/app-language"
import { appTheme } from "../helpers/app-theme"

Vue.use(Vuetify)

const theme = appTheme()

export default new Vuetify({
  lang: {
    locales: { uk, en },
    current:
      appLanguage() ||
      process.env.VUE_APP_I18N_LOCALE ||
      process.env.VUE_APP_I18N_FALLBACK_LOCALE ||
      "en",
  },
  options: { customProperties: true },
  theme: {
    dark: theme.dark,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        error: "#ff5252",
        ...theme.colors,
      },
      dark: {
        error: "#ff5252",
        ...theme.colors,
      },
    },
  },
})
