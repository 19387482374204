import router from "@/router";
import getBrowserInfo from "@/helpers/get-browser-info";
import { processingForm } from "@/helpers/processing-form";
import { getAllUrlParameters } from "@/helpers/url-parameter";

const state = () => ({
  formFields: {
    pan: false,
    holder: false,
    expireDate: false,
    cvv: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    postalCode: false,
  },
  formFieldsPrefilled: {},
  status: null,
  amount: 0,
  currency: "",
  expiredIn: 0,
  shop: {
    logo: "",
    language: "",
    theme: "",
    publicOffer: {
      isEnabled: false,
      text: "",
      links: [],
    },
  },
  authPayload: null,
  loadDataError: null,
  sendDataError: null,
  redirecting: false,
  completed: false,
  otpForm: false,
  uuid: null,
  isProcessing: false,
});

const actions = {
  async fetchWidgetData({ commit, dispatch }, uuid) {
    try {
      commit("setCompleted", false);

      const { data } = await this.$axios.get(
        `api/v1/transactions/processing/${uuid}`,
        { disableErrorNotification: true }
      );

      if (data.status === "processing" && data.authPayload) {
        dispatch("processing", data.authPayload);
      }

      commit("setStatus", data.status);
      commit("setFormFields", data.shop.form.fields);
      commit("setFormFieldsPrefilled", data.shop?.form.prefilled);
      commit("setAmount", data.amount);
      commit("setCurrency", data.currency);
      commit("setExpiredIn", data.expiredIn);
      commit("setShop", data.shop);
      commit("setAuthPayload", data.authPayload);
    } catch (error) {
      const message = error.response.data.error;
      commit("setLoadDataError", message);
    }
  },

  async sendCardData({ commit, dispatch }, { uuid, ...sender }) {
    try {
      const { data } = await this.$axios.post(
        `api/v1/transactions/processing/${uuid}`,
        {
          sender,
          browser: await getBrowserInfo(),
        }
      );

      dispatch("checkResponseStatus", data.data);
    } catch (error) {
      const { errors } = error.response.data;
      if (errors) return { errors };

      const message = error.response
        ? error.response.data.error
        : error.message;

      commit("setSendDataError", message);
    }
  },

  async otpConfirm({ commit }, { uuid, code }) {
    try {
      await this.$axios.post(`api/v1/transactions/otp-confirm/${uuid}`, {
        code,
      });
      const params = getAllUrlParameters();
      router.replace({ path: `/result/${uuid}`, query: { ...params } });
    } catch (error) {
      const message = error.response
        ? error.response.data.error
        : error.message;

      commit("setSendDataError", message);
    }
  },

  checkResponseStatus({ dispatch, commit }, data) {
    if (data.status === "processing") {
      if (data.authPayload) dispatch("processing", data.authPayload);
      else {
        const params = getAllUrlParameters();
        router.replace({ path: `/result/${data.id}`, query: { ...params } });
      }
      return;
    }

    commit("setCompleted", true);
  },

  async processing({ commit, dispatch }, authPayload) {
    commit("setIsProcessing", true);
    if ( !authPayload ) return;
    switch (authPayload.mode) {
      case "form": {
        processingForm(authPayload);
        break;
      }
      case "redirect": {
        if (authPayload.content?.action) {
          window.location.href = authPayload.content.action;
          break;
        }

        const params = getAllUrlParameters();
        router.replace({
          path: `/result/${authPayload.id}`,
          query: { ...params },
        });

        window.open(authPayload.redirectUrl, "_self");
        break;
      }
      case "renderHtml": {
        dispatch("renderHtmlForm", authPayload.content);

        const params = getAllUrlParameters();
        router.replace({
          path: `/result/${authPayload.id}`,
          query: { ...params },
        });
        break;
      }
      case "otp": {
        commit("setOtpForm", true);
        break;
      }
      case "render_html_on_new_page": {
        localStorage.setItem("redirect-page-content", authPayload.content);
        router.replace({ path: `/redirect/${authPayload.id}` });
        break;
      }
      case "hiddenIframe": {
        const container = document.createElement("div");
        container.innerHTML = authPayload.content["iframe"];
        document.body.append(container);
        await container.querySelector("form").submit();

        if (authPayload.content["acsRedirect"]) {
          window.open(authPayload.content["acsRedirect"], "_self");
        } else if (authPayload.content["id"]) {
          const params = getAllUrlParameters();
          router.replace({
            path: `/result/${authPayload.content["id"]}`,
            query: { ...params },
          });
        }

        break;
      }
      case "iframe": {
        let element;
        if (window.self !== window.top) {
          element = document.getElementById("widgetId");
          element.style.height = "100%";
          element.style.marginTop = 0;
        } else {
          element = document.getElementById("widgetInnerId");
          element.style.width = "780px";
          element.style.height = "560px";
        }

        element.innerHTML = authPayload.content["iframe"];

        element.querySelector("form").submit();

        const { uuid } = authPayload.content;
        const timer = setInterval(async () => {
          const { data } = await this.$axios(
            `api/v1/transactions/status/${uuid}`,
            {
              disableErrorNotification: true,
            }
          );

          if (data.authPayload) dispatch("processing", data.authPayload);

          if (data.status !== "processing") {
            clearInterval(timer);

            await router.replace({
              path: `/result/${uuid}`,
              query: { ...getAllUrlParameters() },
            });
          }
        }, 2500);

        break;
      }
    }
  },

  renderHtmlForm({ commit }, template) {
    commit("setRedirecting", true);

    const isFrame = template.includes("<iframe");
    const container = document.createElement("div");

    container.innerHTML = template;

    if (isFrame) {
      container.classList.add("frame-container");
    } else {
      container.style.display = "none";
    }

    document.body.append(container);
    container.querySelector("form").submit();
  },
};

const mutations = {
  setFormFields(state, payload) {
    state.formFields = payload;
  },
  setFormFieldsPrefilled(state, payload) {
    state.formFieldsPrefilled = payload;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  setAmount(state, payload) {
    state.amount = payload;
  },
  setCurrency(state, payload) {
    state.currency = payload;
  },
  setExpiredIn(state, payload) {
    state.expiredIn = payload;
  },
  setShop(state, { language, logo, theme, publicOffer }) {
    state.shop.language = language;
    state.shop.logo = logo;
    state.shop.theme = theme;
    state.shop.publicOffer = publicOffer;
  },
  setAuthPayload(state, payload) {
    state.authPayload = payload;
  },
  setLoadDataError(state, payload) {
    state.loadDataError = payload;
  },

  setSendDataError(state, payload) {
    state.sendDataError = payload;
  },

  setIsProcessing(state, payload) {
    state.isProcessing = payload;
  },

  setRedirecting(state, payload) {
    state.redirecting = payload;
  },

  setCompleted(state, payload) {
    state.completed = payload;
  },

  setOtpForm(state, payload) {
    state.otpForm = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
