import store from "@/store";
import axios from "axios";
import { chunk } from "lodash";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  disableErrorNotification: false,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.common["Access-Token"] = token;
  }

  return config;
});

instance.interceptors.response.use(null, (request) => {
  const { response, config } = request;

  if (401 === response.status) {
    store.dispatch("auth/logout");
  }

  const isValidationError = response.status === 422;
  const needNotification =
    !isValidationError && !config.disableErrorNotification;

  if (needNotification) {
    const errors = [];

    const data =
      response.data instanceof ArrayBuffer
        ? JSON.parse(Buffer.from(response.data).toString())
        : response.data;

    if (isValidationError) {
      data.errors.forEach((err) => errors.push(err.message));
    } else {
      errors.push(data.error || response.message);
    }

    chunk(errors, 3).forEach((items) => {
      store.dispatch("notifications/add", {
        type: "error",
        text: items.join(". "),
      });
    });
  }

  return Promise.reject(request);
});

store.$axios = instance;
