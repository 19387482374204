export const getUrlParameter = (name) => {
  return new URLSearchParams(
    new URL(window.location).hash.slice(1).split("?")[1]
  ).get(name)
}

export const getAllUrlParameters = () => {
  const result = {}
  if (getUrlParameter("theme")) result.theme = getUrlParameter("theme")
  if (getUrlParameter("locale")) result.language = getUrlParameter("locale")

  return result
}
