<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
import { getFavicon } from "@/helpers/favicon-by-brand.js";
import { appThemeFontFamily } from "@/helpers/app-theme.js";

export default {
  metaInfo: {
    title: "spay-widget",
    titleTemplate: "%s | spay-widget",
  },
  mounted() {
    const fontFamily = appThemeFontFamily();
    if (fontFamily)
      document.getElementById("app").style.fontFamily = fontFamily;

    const fav = document.getElementById("favicon");
    fav.href = `${window.location.origin}/${this.getFavicon()}`;
  },
  methods: { getFavicon },
};
</script>

<style lang="scss" scoped>
html,
body,
html body .v-application {
  color: var(--v-color-base);
  background: var(--v-background-base);
}
</style>
