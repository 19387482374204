import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "@/store";
import i18n from "./i18n";
import "@/plugins/axios";
import "@/plugins/vue-meta";
import "@/assets/styles/index.scss";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
