import FingerprintJS from "@fingerprintjs/fingerprintjs";

const getBrowserInfo = async () => {
  const fpConfig = await FingerprintJS.load({
    monitoring: false,
  });

  const { visitorId } = await fpConfig.get();

  return {
    fingerprint: visitorId,
    screen: {
      width: screen.width,
      height: screen.height,
      colorDepth: screen.colorDepth,
    },
    timezone: {
      name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: new Date().getTimezoneOffset(),
    },
    language: navigator.language,
    javaEnabled: false,
    jsEnabled: true,
  };
};

export default getBrowserInfo;
